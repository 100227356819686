import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import axios from "axios";
import env from "react-dotenv";

import Main from "./Main";
import Login from "./Login";

import logo from "./img/eezery.png";

axios.defaults.baseURL = env.API_URL;

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("jwt_token") === "undefined") {
      setLoggedIn(false);
    }
    axios
      .get("/user/info", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        },
      })
      .then((res) => {
        if (res.data.message === "OK") {
          setLoggedIn(true);
        }
      })
      .catch((err) => {
        setLoggedIn(false);
      });
  }, []);

  return (
    <Router>
      <Switch>
      <Route path={`/`}>
        <img
          src={logo}
          alt="Eezery"
          style={{ maxWidth: "20%" }}
          className="pl-3 pt-3"
        />
        {loggedIn ? <Main /> : <Login />}
      </Route>
      </Switch>
    </Router>
  );
}

export default App;
