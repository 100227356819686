import React, { useState, useEffect } from "react";
import axios from "axios";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fi from "date-fns/locale/fi";

import ExportExcel from "./ExportExcel";
import ExportPDF from "./ExportPDF";
import DataTable from "./DataTable";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

function Logout() {
  localStorage.setItem("jwt_token", "undefined");
  window.location.reload();
}

function Main() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showData, setShowData] = useState(false);
  const [adminMessage, setAdminMessage] = useState("Hae kaikki kuljetukset");
  const [searchingData, setSearchingData] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [customers, setCustomers] = useState([]);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [data, setData] = useState([]);
  registerLocale("fi", fi);

  useEffect(() => {
    axios
      .get("user/info", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        },
      })
      .then((res) => {
        if (res.data.user.superuser) {
          axios
            .get(`intra/enterprises`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
              },
            })
            .then((res) => {
              var customers = [];
              res.data.data.forEach((customerPack) => {
                let concernIds = "";

                customerPack.customers.forEach((customer) => {
                  concernIds += customer.id + ",";
                });
                concernIds = concernIds.slice(0, -1); // Poistetaan viimeinen ","
                let concernData = customerPack;
                concernData.id = concernIds;

                if (concernData.customers.length <= 1) {
                  customers.push([concernData, "", true]);
                } else {
                  customers.push([concernData, "", false]);
                }
                for (var i = 0; i < customerPack.customers.length; i++) {
                  if (customerPack.customers[i].name !== customerPack.name) {
                    if (i === customerPack.customers.length - 1) {
                      customers.push([
                        customerPack.customers[i],
                        `[${customerPack.name}]`,
                        true,
                      ]);
                    } else {
                      customers.push([
                        customerPack.customers[i],
                        `[${customerPack.name}]`,
                        false,
                      ]);
                    }
                  }
                }
              });
              setCustomers(customers);
              setFilterShow(true);
            })
            .catch((err) => alert(err));

          axios
            .get(`intra/agents`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
              },
            })
            .then((res) => {
              setUsers(res.data.data);
            })
            .catch((err) => alert(err));

          axios
            .get(`intra/teams`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
              },
            })
            .then((res) => {
              setTeams(res.data.data);
            })
            .catch((err) => alert(err));
        }
      })
      .catch((err) => alert(err));
  }, []);

  function getDataFromAPI(command) {
    const startDateFormatted = `${startDate.getDate()}-${
      startDate.getMonth() + 1
    }-${startDate.getFullYear()}`;
    const endDateFormatted = `${endDate.getDate()}-${
      endDate.getMonth() + 1
    }-${endDate.getFullYear()}`;
    const usersID =
      selectedUser && selectedUser !== "Kaikki" ? selectedUser.id : "";
    const customersID =
      selectedCustomer && selectedCustomer !== "Kaikki"
        ? selectedCustomer[0].id
        : "";
    const teamsID =
      selectedTeam && selectedTeam !== "Kaikki" ? selectedTeam.id : "";
    setSearchingData(true);
    setData([]);
    axios
      .get(`/intra/report/orders`, {
        params: {
          start: startDateFormatted,
          end: endDateFormatted,
          users: usersID,
          customers: customersID,
          team: teamsID,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
        },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          var filteredData = res.data.data;
          if (command === "ExportExcel") {
            ExportExcel(filteredData, startDate, endDate);
          } else if (command === "ExportPDF") {
            ExportPDF(filteredData, startDate, endDate);
          }
          setData(filteredData);
        }
        setSearchingData(false);
        setShowData(true);
      })
      .catch((err) => {
        setSearchingData(false);

        axios
          .get("user/info", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
            },
          })
          .then((res) => {
            //Älä tee mitään
          })
          .catch((err) => {
            window.location.reload();
          });
      });
  }

  function openAdminUI(e) {
    e.preventDefault();
    setSelectedUser("");
    setSelectedTeam("");
    setSelectedCustomer("");
    adminMessage === "Hae kaikki kuljetukset"
      ? setAdminMessage("Hae Yksittäiset kuljetukset")
      : setAdminMessage("Hae kaikki kuljetukset");
  }

  async function showDataInBrowser() {
    getDataFromAPI("showData");
  }

  function DownloadDataInExcel() {
    getDataFromAPI("ExportExcel");
  }

  function DownloadDataInPDF() {
    getDataFromAPI("ExportPDF");
  }

  return (
    <div className="content col-10 mx-auto">
      <Row>
        <Col>
          <h6 className="btn pull-right" onClick={() => Logout()}>
            Kirjaudu ulos
          </h6>
          <Card className="p-4">
            <CardHeader>
              <h2>Kuljetusten haku</h2>
            </CardHeader>
            <CardBody className="px-5">
              <h5 className="mb-2">Anna aikaväli</h5>
              <Row className="mb-4">
                <div className="mx-3">
                  <p className="text-center">Alku pvm</p>
                  <DatePicker
                    locale="fi"
                    placeholder="Valitse pvm"
                    dateFormat="dd.MM.yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>

                <div className="mx-3">
                  <p className="text-center">Loppu pvm</p>
                  <DatePicker
                    locale="fi"
                    placeholder="Valitse pvm"
                    dateFormat="dd.MM.yyyy"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              </Row>
              {filterShow ? (
                <div>
                  <a
                    href="#"
                    className="mb-2 h5"
                    id="adminui"
                    onClick={(e) => {
                      openAdminUI(e);
                    }}
                  >
                    {adminMessage}
                  </a>
                  {adminMessage !== "Hae kaikki kuljetukset" ? (
                    <div className="mt-2">
                      <Row>
                        <UncontrolledDropdown className="mx-3">
                          <DropdownToggle caret>Kuljettaja</DropdownToggle>
                          <DropdownMenu className="pre-scrollable">
                            <DropdownItem
                              onClick={() => setSelectedUser("Kaikki")}
                            >
                              Kaikki kuljettajat
                            </DropdownItem>
                            <DropdownItem divider />
                            {users
                              .sort((a, b) =>
                                a.firstname > b.firstname ? 1 : -1
                              )
                              .map((user) => (
                                <div key={user.id}>
                                  <DropdownItem
                                    onClick={() => setSelectedUser(user)}
                                  >
                                    {user.firstname} {user.lastname}
                                  </DropdownItem>
                                  <DropdownItem divider />
                                </div>
                              ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>

                        <UncontrolledDropdown className="mx-3">
                          <DropdownToggle caret>Tiimi</DropdownToggle>
                          <DropdownMenu className="pre-scrollable">
                            <DropdownItem
                              onClick={() => setSelectedTeam("Kaikki")}
                            >
                              Kaikki tiimit
                            </DropdownItem>
                            <DropdownItem divider />
                            {teams.map((team) => (
                              <div key={team.id}>
                                <DropdownItem
                                  onClick={() => setSelectedTeam(team)}
                                >
                                  {team.name}
                                </DropdownItem>
                                <DropdownItem divider />
                              </div>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>

                        <UncontrolledDropdown className="mx-3">
                          <DropdownToggle caret>Asiakas</DropdownToggle>
                          <DropdownMenu className="pre-scrollable">
                            <DropdownItem
                              onClick={() => setSelectedCustomer("Kaikki")}
                            >
                              Kaikki asiakkaat
                            </DropdownItem>
                            <DropdownItem divider />
                            {customers.map((customer, index) => (
                              <div key={index}>
                                <DropdownItem
                                  onClick={() => setSelectedCustomer(customer)}
                                >
                                  {customer[0].name} {customer[1]}
                                </DropdownItem>
                                {customer[2] ? (
                                  <DropdownItem divider />
                                ) : (
                                  <div />
                                )}
                              </div>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Row>
                      <h5>Valittu:</h5>
                      <p className="ml-3">
                        Kuljettaja:{" "}
                        {selectedUser !== "" && selectedUser !== "Kaikki"
                          ? `${selectedUser.firstname} ${selectedUser.lastname}`
                          : "Kaikki kuljettajat"}
                      </p>
                      <p className="ml-3">
                        Tiimi:{" "}
                        {selectedTeam !== "" && selectedTeam !== "Kaikki"
                          ? selectedTeam.name
                          : "Kaikki tiimit"}
                      </p>
                      <p className="ml-3">
                        Asiakas:{" "}
                        {selectedCustomer !== "" &&
                        selectedCustomer !== "Kaikki" ? (
                          <>
                            {selectedCustomer[0].name} {selectedCustomer[1]}
                          </>
                        ) : (
                          "Kaikki asiakkaat"
                        )}
                      </p>
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
              ) : (
                <div />
              )}
            </CardBody>
            <CardFooter>
              <Row className="ml-2 mt-2">
                <Button
                  color="primary"
                  className="ml-3 mb-3"
                  onClick={() => showDataInBrowser()}
                >
                  Hae
                </Button>
                <Button
                  color="primary"
                  className="ml-3 mb-3"
                  onClick={() => DownloadDataInExcel()}
                >
                  Lataa Excel
                </Button>
                <Button
                  color="primary"
                  className="ml-3 mb-3"
                  onClick={() => DownloadDataInPDF()}
                >
                  Lataa PDF
                </Button>
              </Row>
              {searchingData ? (
                <h4 className="ml-4">Etsitään kuljetuksia...</h4>
              ) : (
                <div />
              )}
            </CardFooter>
          </Card>
        </Col>
      </Row>
      {showData ? <DataTable className="md-5" data={data} /> : <div />}
    </div>
  );
}

export default Main;