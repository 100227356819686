import * as FileSaver from "file-saver";
import * as ExcelMK from "sheetjs-style";

import DateCell from "./DateCell";

function ExportExcel(apiData, startDate, endDate) {
  let filteredData = [];
  let totalCost = 0;
  apiData.forEach((piece) => {
    totalCost += piece.maksetaan;
    filteredData.push([
      piece.tilausnro,
      piece.kuljettaja,
      piece.tiimi,
      piece.kauppias,
      piece.tuote,
      piece.lisakilometrit,
      piece.lisapaketit,
      piece.maksetaan,
      DateCell(piece.noutoaika),
      DateCell(piece.toimitusaika),
    ]);
  });
  filteredData.push(["", "", "", "", "", "", "", "=="]);
  filteredData.push(["", "", "", "", "", "", "", parseFloat(totalCost.toFixed(2))]);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const worksheet = ExcelMK.utils.json_to_sheet(filteredData);

  const styledWorksheet = styleSheet(worksheet);
  const workbook = {
    Sheets: { raportti: styledWorksheet },
    SheetNames: ["raportti"],
  };
  const excelBuffer = ExcelMK.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(
    data,
    `Kuljettajaraportti (${getDate(startDate)} - ${getDate(endDate)}).xlsx`
  );
}

function getDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

function styleSheet(worksheet) {
  worksheet["A1"].s = {
    font: {
      bold: true,
    },
  };
  worksheet["B1"].s = {
    font: {
      bold: true,
    },
  };
  worksheet["C1"].s = {
    font: {
      bold: true,
    },
  };
  worksheet["D1"].s = {
    font: {
      bold: true,
    },
  };
  worksheet["E1"].s = {
    font: {
      bold: true,
    },
  };
  worksheet["F1"].s = {
    font: {
      bold: true,
    },
  };
  worksheet["G1"].s = {
    font: {
      bold: true,
    },
  };
  worksheet["H1"].s = {
    font: {
      bold: true,
    },
  };
  worksheet["I1"].s = {
    font: {
      bold: true,
    },
  };
  worksheet["J1"].s = {
    font: {
      bold: true,
    },
  };
  var wscols = [
    { wch: 10 },
    { wch: 18 },
    { wch: 18 },
    { wch: 38 },
    { wch: 38 },
    { wch: 14 },
    { wch: 14 },
    { wch: 14 },
    { wch: 18 },
    { wch: 18 },
  ];
  worksheet["!cols"] = wscols;
  worksheet["A1"].v = "Tilausnro";
  worksheet["B1"].v = "Kuljettaja";
  worksheet["C1"].v = "Tiimi";
  worksheet["D1"].v = "Asiakas";
  worksheet["E1"].v = "Tuote";
  worksheet["F1"].v = "Lisäkm";
  worksheet["G1"].v = "Lisäkollit";
  worksheet["H1"].v = "Maksetaan";
  worksheet["I1"].v = "Nouto";
  worksheet["J1"].v = "Toimitus";
  return worksheet;
}

export default ExportExcel;
