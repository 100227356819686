import jsPDF from "jspdf";
import "jspdf-autotable";

import DateCell from "./DateCell";

function ExportPDF(data, startDate, endDate) {
  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const title = `Kuljettajaraportti (${getDate(startDate)} - ${getDate(
    endDate
  )})`;
  const headers = [
    [
      "Tilausnro",
      "Kuljettaja",
      "Tiimi",
      "Asiakas",
      "Tuote",
      "Lisäkm",
      "Lisäkollit",
      "Maksetaan",
      "Nouto",
      "Toimitus",
    ],
  ];

  const sheets = data.map((piece) => [
    piece.tilausnro,
    piece.kuljettaja,
    piece.tiimi,
    piece.kauppias,
    piece.tuote,
    piece.lisakilometrit,
    piece.lisapaketit,
    piece.maksetaan.toFixed(2).replace(".", ",") + " €",
    DateCell(piece.noutoaika),
    DateCell(piece.toimitusaika),
  ]);
  let totalCost = 0;
  data.forEach((piece) => {
    totalCost += piece.maksetaan;
  });
  sheets.push(["", "", "", "", "", "", "", "==", "", ""]);
  sheets.push([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    `${totalCost.toFixed(2).toString().replace(".", ",")} €`,
    "",
    "",
  ]);

  let content = {
    startY: 50,
    head: headers,
    body: sheets,
  };

  doc.text(title, marginLeft, 40);
  doc.autoTable(content);
  doc.save(
    `Kuljettajaraportti (${getDate(startDate)} - ${getDate(endDate)}).pdf`
  );
}

function getDate(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

export default ExportPDF;
