import React, { useState } from "react";
import axios from "axios";

import "./Login.css";

import {
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CardHeader,
  CardBody,
} from "reactstrap";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [showBadEntry, setShowBadEntry] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();

    axios
      .post("/auth/login", {
        username: `${username}`,
        password: `${password}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        localStorage.setItem("jwt_token", res.data.token);
        setUsername("");
        setPassword("");
        setShowBadEntry(false);
        window.location.reload();
      })
      .catch((err) => {
        setUsername("");
        setPassword("");
        setShowBadEntry(true);
      });
  }

  return (
    <div className="content py-5 mx-auto Login">
      <Card>
        <CardHeader>
          <h5 className="text-center py-2">Kirjaudu sisään</h5>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit} className="p-5">
            <FormGroup>
              <Label>Käyttäjätunnus</Label>
              <Input
                autoFocus
                type="text"
                name="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Salasana</Label>
              <Input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
            <Button
              block
              size="lg"
              className="my-3"
              color="primary"
              type="submit"
            >
              Kirjaudu
            </Button>
            {showBadEntry ? (
              <h6 className="text-center text-danger">
                Käyttäjätunnus tai salasana on väärin
              </h6>
            ) : (
              <div />
            )}
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}

export default Login;
