import React from "react";

import DateCell from "./DateCell";
import { Table } from "reactstrap";

function DataTable(props) {
  return (
    <Table striped>
      <thead>
        <tr>
          <th>Tilausnro</th>
          <th>Kuljettaja</th>
          <th>Tiimi</th>
          <th>Asiakas</th>
          <th>Tuote</th>
          <th>Lisäkm</th>
          <th>Lisäkollit</th>
          <th>Maksetaan</th>
          <th>Noutoaika</th>
          <th>Toimitusaika</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((piece) => (
          <tr key={piece.id}>
            <th style={{ maxWidth: "150px" }}>{piece.tilausnro}</th>
            <th style={{ maxWidth: "150px" }}>{piece.kuljettaja}</th>
            <th style={{ maxWidth: "150px" }}>{piece.tiimi}</th>
            <th style={{ maxWidth: "150px" }}>{piece.kauppias}</th>
            <th style={{ maxWidth: "150px" }}>{piece.tuote}</th>
            <th style={{ maxWidth: "150px" }}>{piece.lisakilometrit}</th>
            <th style={{ maxWidth: "150px" }}>{piece.lisapaketit}</th>
            <th style={{ maxWidth: "150px" }}>
              {piece.maksetaan.toFixed(2).replace(".", ",")} €
            </th>
            <th style={{ maxWidth: "150px" }}>{DateCell(piece.noutoaika)}</th>
            <th style={{ maxWidth: "150px" }}>
              {DateCell(piece.toimitusaika)}
            </th>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default DataTable;
